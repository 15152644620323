body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex: 1;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a > button {
  min-height: 40px;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.faicon {
  width: 35px;
  height: 35px;
}

/**
 * Custom Nerd UI styles
 */

/* TODO: move to isolated defualt theme */
.nerdUI .nerdUI--label {
  font-size: 0.875rem;
}
